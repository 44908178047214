// @flow strict
import React from 'react';
import Content from './Content';
import styles from './Post.module.scss';
import type { Node } from '../../types';
import { isBrowser } from 'react-device-detect';
import moment from 'moment';

import { Button, Header, Menu, Container, Item, Responsive, Divider } from 'semantic-ui-react';

type Props = {
  post: Node
};

const h1Style = {
  textAlign: 'center',
  marginTop: '15px'
};

const h3Style = {
  textAlign: 'center',
  marginTop: '15px',
  paddingTop: '5px',
  paddingBottom: '10px',
  backgroundColor: 'white'
};

const aStyle = {
  fontWeight: 'bold',
  fontSize: '22px',
  color: 'white'
};

function authorPosts(contentArray) {
  const elements = [];
  for (let i = 0; i < 10; i += 1) {
    if (contentArray[i]) {
      elements.push(
          <Item.Group divided='vertically' style={{
            backgroundColor: i % 2 === 0 ? 'white' : '#F5F5F5',
            maxWidth: '100%',
            overflow: 'auto',
            whiteSpace: 'break-wrap',
            borderLeft: '1px dotted grey',
            borderRight: '1px dotted grey',
            marginTop: '0px',
            marginBottom: '0px'
          }}>
            <Item>
              <Responsive minWidth={400}>
                <Item.Image src={`https://images.weserv.nl/?url=${contentArray[i].node.fields.images && contentArray[i].node.fields.images.length ? contentArray[i].node.fields.images[0] : 'https://www.smoke-indica.com/photo.jpg'}&w=${isBrowser ? 200 : 150}&h=150&fit=contain&cbg=transparent&output=webp`}
                as='a'
                size='medium'
                href={contentArray[i].node.fields.slug}
                alt='Main article image' />
              </Responsive>
              <Item.Content style={{paddingTop: '20px'}}>
                <Item.Header as='a' href={contentArray[i].node.fields.slug} style={{wordWrap: 'break-word'}}>
                    {contentArray[i].node.frontmatter.title}
                </Item.Header>
                <Item.Meta>
                  <a href={`/${contentArray[i].node.frontmatter.author}`} style={{ color: 'green', fontWeight: 'bold' }}>@{contentArray[i].node.frontmatter.author}</a> created this post on {moment(contentArray[i].node.frontmatter.date).format('ll')}<br/>
                </Item.Meta>
                <Item.Description>
                    {contentArray[i].node.frontmatter.tags.map((tag) =>
                       <Button basic size='tiny' href={`/tag/${tag}`} style={{margin: '1px'}}>{tag}</Button>
                      )}
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
      );
    }
  }
  return elements;
}

// //////////////////////

const imgStyle = {
  float: 'left',
  paddingRight: '5px'
};

function generateLiElements(currentKeyPosts) {
  const displayedPostPermalinks = [];
  const titleArrayTags = [];
  const aElements = [];

  const trDivLost = {
    width: '100%',
    marginBottom: '5px'
  };

  const tdIMG = {
    width: '25%',
    borderBottom: '1px dotted grey'
  };

  const tdTXT = {
    width: '75%',
    borderBottom: '1px dashed grey'
  };

  for (let i = 0; i < 6; i += 1) {
    // const rnd = Math.floor(Math.random() * currentKeyPosts.length) + 1;
    currentKeyPosts.forEach((item, itr) => {
      const currentItem = item.node.frontmatter;
      const currentFields = item.node.fields;
      if (
        !displayedPostPermalinks.includes(item.node.fields.slug)
        && !titleArrayTags.includes(currentItem.title)
      ) {
        displayedPostPermalinks.push(item.node.fields.slug);
        titleArrayTags.push(currentItem.title);
        aElements.push(
          <Item.Group divided='vertically' style={{
            backgroundColor: itr % 2 === 0 ? 'white' : '#F5F5F5',
            maxWidth: '100%',
            overflow: 'auto',
            whiteSpace: 'break-wrap',
            borderLeft: '1px dotted grey',
            borderRight: '1px dotted grey',
            marginTop: '0px',
            marginBottom: '0px'
          }}>
            <Item>
              <Responsive minWidth={400}>
                <Item.Image src={`https://images.weserv.nl/?url=${item.node.fields.images && item.node.fields.images.length ? item.node.fields.images[0] : 'https://www.smoke-indica.com/photo.jpg'}&w=${isBrowser ? 200 : 150}&h=150&fit=contain&cbg=transparent&output=webp`}
                as='a'
                size='medium'
                href={item.node.fields.slug}
                alt='Main article image' />
              </Responsive>
              <Item.Content style={{paddingTop: '20px'}}>
                <Item.Header as='a' href={item.node.fields.slug} style={{wordWrap: 'break-word'}}>
                    {item.node.frontmatter.title}
                </Item.Header>
                <Item.Meta>
                  <a href={`/${item.node.frontmatter.author}`} style={{ color: 'green', fontWeight: 'bold' }}>@{item.node.frontmatter.author}</a> created this post on {moment(item.node.frontmatter.date).format('ll')}
                  <br/>
                  <br/>
                  {item.node.frontmatter.net_votes} users rewarded @{item.node.frontmatter.author} {item.node.frontmatter.sortvalue} Smoke for this post over on <a href={'https://smoke.io/@' + item.node.frontmatter.author + item.node.fields.slug}>smoke.io</a>.
                </Item.Meta>
                <Item.Description>
                    {item.node.frontmatter.tags.map((tag) =>
                       <Button basic size='tiny' href={`/tag/${tag}`} style={{margin: '1px'}}>{tag}</Button>
                      )}
                </Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        );
      }
    });
  }

  return aElements;
}

function tagElement(contentTagPosts) {
  const response = [];
  const keys = Object.keys(contentTagPosts);

  keys.forEach((tagStr) => {
    // Each tag creates following
    response.push(<Divider />);
    response.push(
      <Header as='h2' style={{backgroundColor:'#ebebfa', padding:'5px'}}><a href={`/tag/${tagStr}`}><span style={{color: 'black'}}>Related "</span><span style={{color: '#3a7f00'}}>{ tagStr }</span><span style={{color: 'black'}}>" posts:</span></a></Header>
    );

    // Shuffle array
    const shuffled = contentTagPosts[tagStr].sort(() => 0.5 - Math.random());

    // Get sub-array of first n elements after shuffled
    const nElements = shuffled.length > 6
      ? 6
      : shuffled.length;

    const selectedPosts = shuffled.slice(0, nElements);

    response.push(generateLiElements(selectedPosts));
  });
  return response;
}

// //////////////////////

const tagDivStyle = {
  backgroundColor: 'white',
  paddingTop: '10px',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginRight: '10px',
  height: '100%',
  width: '100%'
};


// //////////////////////

const Post = ({
  post, authorArray, contentArray, contentTagPosts
}: Props) => {
  const { html } = post;
  const {
    tagSlugs,
    slug,
    author,
    images,
    net_votes,
    total_payout_value,
    pending_payout_value
  } = post.fields;
  const { tags, title, date } = post.frontmatter;

  return (
    <div className={styles['post']}>
      <Header style={{marginBottom: '5px'}}>
        <Menu secondary>
          <img
            src={'/photo.jpg'}
            width="45"
            height="45"
          />
          <Menu.Item header href='/'>
            Smoke-Indica.com
          </Menu.Item>
        </Menu>
        <Button.Group compact style={{marginTop: '5px'}}>
          <Button href='/' icon='home' />
          <Button href='/pages/about'>About</Button>
          <Button href='/pages/related'>Related</Button>
          <Button href='/pages/stats'>Stats</Button>
          <Button href='/pages/explorers'>Explorers</Button>
        </Button.Group>
      </Header>
      <div className={styles['post__content']}>
        <Content
          body={html}
          title={title}
          imgsrc={images[0]}
          images={images}
          tags={tags}
          tagSlugs={tagSlugs}
          author={author}
          date={date}
          netVotes={net_votes}
          totalPayoutValue={total_payout_value}
          pendingPayoutValue={pending_payout_value}
          postSlug={slug}
          profile_image={authorArray.profile_image}
          rep={authorArray.reputation}
          about={authorArray.about_author}
        />

        <Header as="h2">
          <a style={{color: '#3a7f00'}} href={`https://smoke.io/@${author}${slug}`}>Want to comment on this post?<br/>Head on over to Smoke.io</a><br/>
          </Header>
        <Divider />
        <Header as="h2" style={{color: 'black', backgroundColor: 'gold', padding: '5px'}}>
          <a href={`/${author}`}><span style={{color:'black'}}>More content by @{author}</span></a>
        </Header>
        {authorPosts(contentArray, author)}
        {tagElement(contentTagPosts)}
      </div>

    </div>
  );
};

export default Post;
