// @flow strict
import React from 'react';
import moment from 'moment';
import {
  isBrowser,
  isMobile
} from 'react-device-detect';
import styles from './Content.module.scss';

import { Button, Header, Card, Divider, Icon } from 'semantic-ui-react';

const showdown = require('showdown');

const converter = new showdown.Converter();

type Props = {
  body: string,
  title: string,
  imgsrc: string,
  author: string,
  tags: string,
  tagSlugs: string,
  date: string,
  netVotes: string,
  totalPayoutValue: string,
  pendingPayoutValue: string,
  postSlug: string
};

const imgCSS = {
  minWidth: '100%',
  paddingTop: '0px'
};

let titleCSS;
if (isBrowser) {
  titleCSS = {
    fontSize: '32px',
    marginTop: '0px',
    marginBottom: '0px',
    paddingLeft: '0px'
  };
} else if (isMobile) {
  titleCSS = {
    fontSize: '32px',
    marginTop: '0px',
    marginBottom: '0px',
    paddingLeft: '0px'
  };
}

let subtitleCSS;
if (isBrowser) {
  subtitleCSS = {
    marginTop: '0px',
    marginBottom: '0px',
    fontWeight: '500',
    paddingLeft: '0px'
  };
} else if (isMobile) {
  subtitleCSS = {
    marginTop: '0px',
    marginBottom: '0px',
    fontWeight: '500',
    paddingLeft: '0px'
  };
}

const hrCSS = {
  marginTop: '20px',
  marginBottom: '0px'
};

function filteredHTML(html, images) {
  const currentHTML = html;
  for (let i = 0; i < images.length; i += 1) {
    const item = images[i];
    const current = `/>${item}</gi`;
    const replacement = `><img src="${item}" /><`;
    currentHTML.replace(
      current,
      replacement
    );
  }
  return currentHTML;
}

const Content = ({
  body, title, imgsrc, images, tags, author, date, netVotes, totalPayoutValue, pendingPayoutValue, postSlug
}: Props) => (
  <div className={styles['content']}>
    {<Card fluid>
      <Card.Content>
        <Card.Header><Header as="h2">{title}</Header></Card.Header>
        <Card.Meta style={{paddingTop: '5px'}}>
          <Header as="h3">
            By <a href={`/${author}`}>@{author}</a> on {moment(date).format('D MMM YYYY')}.
          </Header>
        </Card.Meta>
        <Card.Description>
          <Header as="h4">
            {netVotes} users rewarded @{author} {parseInt((totalPayoutValue).replace(' SMOKE', ''), 10) + parseInt((pendingPayoutValue).replace(' SMOKE', ''), 10)} Smoke for this post over on <a href={`https://smoke.io/@${author}${postSlug}`}>Smoke.io</a>.<br/>
          </Header>
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <b>Tags</b>: {tags.map((tag) => <Button basic size='tiny' style={{margin: '2px'}} href={`/tag/${tag}`}>{tag}</Button>)}
        <br/>
        <b>Share</b>:
          <Button color='twitter' size='tiny' style={{margin: '2px'}} href={`https://twitter.com/intent/tweet?url=https://smoke-indica.com/${author}/&text=${title} - ${author}&hashtags=${tags.join(',')}`}>
            <Icon name='facebook' /> Twitter
          </Button>
          <Button color='reddit' size='tiny' style={{margin: '2px'}} href={`https://reddit.com/submit?url=https://smoke-indica.com/${author}/&title=${title} - ${author} - ${tags.join(', ')}`}>
            <Icon name='reddit' /> Reddit
          </Button>
      </Card.Content>
    </Card>}
    <Divider />

    <div style={{borderLeft: '1px dotted grey', borderRight: '1px dotted grey', padding: '14px'}} dangerouslySetInnerHTML={{ __html: filteredHTML(converter.makeHtml(body), images) }} />
  </div>
);

export default Content;
